/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.black {
    background-color: black;
} 

.grey {
    background-color: #0f0f0f;

} 

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.accordion-button {
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    font-style: italic;
    font-weight: 400;
    outline: none;
}


.accordion-button:hover {
    background-color: rgb(65, 65, 65);
}

.accordion-button[aria-expanded="true"]{
    background-color: rgb(77, 77, 77);
}

.accordion-button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 1px;
    margin-right: 15px;
    transform: rotate(90deg);
    background-color: white;

}


.accordion-button:after {
    position: absolute;

    left: -21px;
    content: '';
    height: 10px;
    width: 1px;
    margin-left: 40px;
    margin-top: 6px;

    background-color: white;

}

.accordion-button[aria-expanded='true']::after,
.accordion-button[aria-selected='true']::after {
    transform: rotate(90deg);
    transition: transform .35s cubic-bezier(.65, .05, .36, 1);
}

.accordion-button[aria-expanded='false']::after,
.accordion-button[aria-selected='false']::after {
    transition: transform .35s cubic-bezier(.65, .05, .36, 1);
}


[hidden] {
    display: none;
}

.accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in-out;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
