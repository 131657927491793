@mixin fade($num:5, $fade:1, $visible:2) {
   $a: 100 / (($fade + $visible) * $num);
   @keyframes fade {
    0% { opacity: 0; }
    #{$a * $fade}%   { opacity: 1; }
    #{$a * ($fade + $visible)}%  { opacity: 1; }
    #{$a * ($fade + $visible + $fade)}%  { opacity: 0; }
    100% { opacity: 0; }
  }

  animation-name: fade; 
  animation-duration: (($fade + $visible) * $num) + s; 
  animation-iteration-count: infinite; 
  animation-timing-function: ease-in;

  
  @for $i from 1 through $num {
    &:nth-child(#{$i}) {
      animation-delay: (($fade + $visible) * ($i - 1)) + s;
    }
  }
}

.background {
  opacity:0; 
  position: fixed;
  z-index: -5;
  width: 100vw;
  height: 100vh;
  background-size: contain;
  @include fade( $num:5, $fade:25, $visible:5,);
}
.background-test {
  opacity:1; 
  position: fixed;
  z-index: -5;
  width: 100vw;
  height: 100vh;
  background-size: contain;
}


@keyframes fade-out{
  0%{
    opacity: 0;
  }

 50%{
    opacity: .1;
  }
  
 100%{
    opacity: 0;
  }
}