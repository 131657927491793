.dim{
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 4;
    opacity: .96;
    background: black;
    animation: fade-in ease-out .2s;
}

.dim.close{
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 3;
    opacity: 0;
    pointer-events: none;
    background: black;
    animation: fade-out .5s;
}

@keyframes fade-in {
    0%{ 
        opacity: 0;
    
    }

    100%{
        opacity: .96;
    }
}


@keyframes fade-out {
    0%{ 
        opacity: .7;
    
    }

    100%{
        opacity: 0;
    }
}