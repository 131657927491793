.particles-galaxy {
    width: 100vw;
    height: 100vh;
    background: #000;
    position: fixed;
    top: 0px;
    z-index: -1;
    
  }

  .particles-polygon {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    z-index: -1;
    animation: particleFadeIn 15s ease-in-out;
    animation-fill-mode: forwards;  
    animation-delay: 0s;
    opacity: 0;
  }
  .multiply {
    mix-blend-mode: multiply;
  }
  .screen {
    mix-blend-mode: screen;
  }

  @keyframes particleFadeIn{
    0% {
        opacity: 0;

    }
  
  
    100% {
        opacity: 1;

    }
  }

  .polygon-transition-in{
    bottom:0px;
    width: 100vw;
    height: 100vh;
    animation: transition-fade-in-and-out ease-in-out 50s;
    opacity: 0;
    animation-fill-mode: forwards; 
    position: absolute;
    background-color: black;
    z-index: -3;
  }

  .polygon-background{
    bottom:0px;
    width: 100vw;
    height: 100vh;

    opacity: 0;
    animation-fill-mode: forwards; 
    position: absolute;
  z-index: -2;
  }

  .polygon-transition-out{
    bottom:0px;
    width: 100vw;
    height: 100vh;
    animation: out-transition-fade-in-and-out ease-in-out 4s;
    opacity: 1;
    animation-fill-mode: forwards; 
    position: absolute;
    background-color: black;
    z-index: -1;
  }

  @keyframes background-fade-in-and-out{
    0%{
      opacity: 0;
    }
    50%{
      opacity:1;
    }
    70%{
      opacity:1;
    }
    100%{
      opacity:0;
    }
  }
  

  @keyframes transition-fade-in-and-out{
    0%{
      opacity: 0;
    }
    10%{
      opacity: 1;
    }
    50%{
      opacity: 1;
    }
    100%{
      opacity:0;
    }
  }
  
  @keyframes out-transition-fade-in-and-out{
    0%{
      opacity: 0;
    }
    50%{
      opacity: 1;
    }
    100%{
      opacity:0;
    }
  }
  