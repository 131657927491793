
#toggle {
    cursor: pointer;
    color: white;
    font-size: 60px;
    margin-right: 20px;
    background: transparent;
    border: none;
    right: 0px;
    padding: 10px;
    position: fixed;
    z-index: 3;
    outline: none;
}

.toggle-image{
    width: 40px;
}

.close-button {
    background-color: transparent;
    border: none;
    color: white;
    padding: 24px 33px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    right: 0px;
    position: absolute;
    font-size: 17px;
    cursor: pointer;
    opacity: .9;
    outline: none;
    font-weight: 600;
}
.close-button:hover {
    opacity: 1;
    color: pink;
      }


.sidebar {
    height: 100vh;
    width: 390px;
    background-image: linear-gradient(to top, rgba(0,0,0,.99), rgb(0, 0, 0));    
    color: white;
    position: fixed;
    z-index: 200;
    right: -400px;
    animation: slide-open .7s forwards;
    -webkit-box-shadow: -5px 1px 8px -2px rgba(0,0,0,0.62);
    -moz-box-shadow: -5px 1px 8px -2px rgba(0,0,0,0.62);
    box-shadow: -5px 1px 8px -2px rgba(0,0,0,0.62);
    display: flex;
    flex-direction: column;
    align-content: center;
}

.sidebar-header {
    width: 100%;
    height: 10%;
    padding-top: 10px;
    margin-bottom: 10px;
}

.sidebar-title {
    left: 20px;
    position: absolute;
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.artist-block{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 11vh;
}

hr.line{
    border: 0;
    clear:both;
    display:block;

    background-color:#FFFFFF;
    height: 1px;
    opacity: .1;
    
}
.top-line{
    margin-top: 0px;
    margin-bottom: 15px;
}
.bottom-line{
    margin-top: 10px;
    margin-bottom: 0px;
}
.artist-title{
    padding-left: 27px;
    font-size: 20px;
    font-style: oblique;
    font-weight: 400;
    padding-top:9px;

}
.artist-songs{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size:14px;
    padding-bottom:10px;
    
}

.accordion__panel{
    background-color: black;
}
/* .sidebar-link1:hover { transform: scale(1.3); }
.sidebar-link2:hover { transform: scale(1.3); } */

.sidebar-link1{
    width: 19px;
    margin-bottom: 30px;
    right: 55px;
    position: absolute;
}
.sidebar-link2{
    width: 19px;
    margin-bottom: 30px;
    right: 22px;
    position: absolute;
}


/*mobile */

@media only screen and (max-width: 768px) /*mobile*/ {
   .sidebar{
    width: 100vw;
    background-color:black;
    opacity: 1;

   } 
   .dim {
        display: none;
   }

  }






.grey{
    background: rgb(10, 10, 10);
}
.black{
    background: black;
}


.sidebar-song-container {
    cursor: pointer;
    
}

.sidebar-song-container:hover {
    opacity: 1 !important;
    color: pink;
  }

.sidebar.close{
    right: 0;
    animation: slide-close .7s forwards ease-in;
    outline: none;
}

.grow { transition: all .3s ease-out; }
.grow:hover { transform: scale(1.1); }

.grow-more { transition: all .2s ease-in-out; }
.grow-more:hover { transform: scale(1.3); }

.grow-link { transition: all .3s ease-in-out; }
.grow-link:hover { transform: scale(1.2); }


@keyframes slide-open {
    100%{
        right: 0%;
    }
}

@keyframes slide-close {
    100%{
        right: -100%;
    }
}
