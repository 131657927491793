
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;

}
html,body {
  height:100%;
}

body {
  background-color: black;
  margin: 0;
  font-family: "Helvetica Neue";
  font-weight: 500;
  color: white;
  font-size: 17px;
  justify-content: center;
}




.top-controls {
  position: relative;

}

.bottom-controls {

  

}

.left-controls {
  position: relative;
  
}

h4 {
  margin: 0;
  font-weight: 400;
  font-size: 22px;
}

.hide {
  display: none;
}

.main {
  height: 0vh;
  width: 100vw;
  flex-direction: column;
  justify-content: space-around;
}

.graphics img {
  border-radius: 8px;
  box-shadow: 0 0 3px 0px #777777b8;
  height: 300px;
}

.graphics {
  padding: 15px 0px;
}

.play_button {
  width: 16px;
  height: 19px;
  bottom: 0%;
}

.pause_button {
  width: 17px;
  height: 19px;
  bottom: 0%;
}

.rain_button {
  width: 28px;
  height: 28px;
  bottom: 0%;
  cursor: pointer;
}

.next {
  position: absolute;
  right: 8px;
  
}


.prev {
  position: absolute;
  right: 58px;
}

.dir{
    bottom: 19px;
    cursor: pointer;
    cursor: pointer;
    opacity: 1;
    width: 13px;
    height: 13px;  
}


.actions {
  padding: 10px;
  background: #3a3a3a;
  display: flex;
  flex-direction: row;
  border-radius: 3px;
}

.actions button {
  background: none;
  border: none;
  color: white;
  -webkit-user-select: none;
  user-select: none;
  width: 32px;
  height: 22px;
}


.playlist {
  height: 100%;
  width: 100vw;
  overflow: hidden;
  z-index: 0;
  position: absolute;
}

.cloud{
  position: absolute;
  visibility: hidden;
  bottom: 0vh;
  left: 0vw;
  height: 100vh;
  width: 100vw;

}

.row{
  position: absolute;
  visibility: hidden;
  height: 60%;
  width: 100%;
  z-index: 2;
  left: 0%;
}


.c5{
  height: 50%;
  width: 50%;
  left:0%;
  z-index: 3;
}

.cl{
  left: 0%;

}

.cr{
  left: 40%;
}


@keyframes falling {

  0%{
    transform: translateY(-10vh);
  }
  100%{
    transform: translateY(200vh);
  }
}




@keyframes float-right {

  0%{
    transform: translateX(-50vw);
  }
  100%{
    transform: translateX(100vw);
  }
}


@keyframes falling_words {

  0%{
    transform: translateY(0vh);
  }

  100%{
    transform: translateY(130vh);
  }
}


@keyframes fadeAndScale1 {
  0% {
      opacity: 0;
      transform: scale3d(.75,.75,1);
  }

  50% {
      opacity: 1;
      transform: scale3d(1,1,1);
  }
  100% {
    opacity: 1;
}
}

@keyframes fadeAndScale2 {
  0% {
      opacity: 0;
      transform: scale3d(.75,.75,1);
  }

  100% {
      opacity: 1;
      transform: scale3d(1,1,1);
  }
}


@keyframes fadeAndScale3 {
  0% {
      opacity: 0;
      transform: scale3d(.75,.75,1);
  }

  40% {
      opacity: 0;
      transform: scale3d(.75,.75,1);
  }

  100% {
      opacity: 1;
      transform: scale3d(1,1,1);
  }
}


@keyframes fadeIn {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

.playlist .header {
  padding: 5px;
}

.pltext {
  padding: 5px;
}

.song {  
  padding: 10px;
}

.selected {
  opacity: 1 !important;
  color: pink;
}

@keyframes select {
  0%{
    color: pink;
  }
  100%{
    color: white;
  }
}

.controls {
  position: absolute;
  width: 100%;
  bottom: 35px;
  padding-top: 0px;
  padding-right: 30px;
  padding-bottom: 13px;
  padding-left: 30px;
  z-index: 5;
}



.ambience-controls {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  color: white;
  align-items: left;
  justify-content: space-around;
}

.vlme {
  display: flex;
  align-content: center;
  top: 31.5px;
  cursor: pointer;
  width: 25px;
  position: absolute;
  right: 14px;
  transform: translate(-50%, -50%) rotate(-90deg);
}
.amb-controls{
  position: absolute;
  right: 0%;
  width: 50px;
  height: 50px;
}

.volum {
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
  padding-left: 10px;
  transform: rotate(90deg);
  opacity: 1;

}
.volBars{
  position: absolute;
}

#volBar {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 7px;
  width: 60px;
  left: 43px;
  background: transparent;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

#visible-volBar{
  pointer-events: none;

  height: 3px;
  padding: 0;
  margin: 0;

  position: absolute;
  top:19px;
  width: calc(60px * .7);
  left: 43px;

  background: white;
  visibility: hidden;

  opacity: 0;
  transition: visibility .2s, opacity 0.2s ease-out;  
}
#visible-volBar-background{
  pointer-events: none;

  height: 3px;
  padding: 0;
  margin: 0;

  position: absolute;
  top: 19px;
  width: 60px;
  left: 43px;

  background: white;
  visibility: hidden;

  opacity: 0;
  transition: visibility .2s, opacity .2s ease-out;  

}

.vlme:hover #visible-volBar{
  visibility: visible;
  opacity: 1;
}

.vlme:hover #visible-volBar-background{
  visibility: visible;
  opacity: .3;
}
  
  
.vlme:hover #volBar{
  visibility: visible;
  opacity: 0;
}

.next-ambience{
  visibility: visible;

  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear;
}


.dir:hover{
  opacity: 1;

}

.prev-ambience{
  visibility: visible;
  opacity: 1;
}

/* 
.amb-controls:hover > .prev-ambience{
  visibility: hidden;
  opacity: 0;
} */


#volBar::-moz-range-thumb {
  height: 10px;
  width: 3px;
  background: #2A6586;
  opacity:0;
  border-radius: 5px;
  cursor: pointer;
}


.NowPlaying {
  margin-left: 44px;
  box-sizing: border-box;
  /* animation: fade-in 2s; */

  /* animation-fill-mode: forwards; */
  /* opacity: 0; */
  font-size: 18px;
  display: flex;
  flex-direction: row;
}

.top-controls {
  display: flex;
  align-items: center;
  padding-bottom: 19px;
}

.play {
  top: 14px;
  left: 4px;
  transform: translateY(-55%);
  position: absolute;
  cursor: pointer;

}

.play:active {
}

.play:hover {
}
/* 
.prev {
  background: #4aa9de;
  border-radius: 10px 0 0px 10px;
  padding-left: 15px;
  padding-right: 10px;
  margin-right: -5px;
  z-index: 1;
}

.next {
  background: #4aa9de;
  border-radius: 0px 10px 10px 0px;
  padding-right: 15px;
  padding-left: 10px;
  margin-left: -5px;
  z-index: 1;
} */

.visible-progress{
  z-index: 5;
  width: calc(100% - 60px);

}

.total-progress{
  width: 100%;
  height: 2px;
  border-radius: 4px;
  /* background-image: linear-gradient(to left, #2ecc71, #3498db, #95b5b6, #f39c12); */

  opacity: 0.3;
  background-color: white;

  position: absolute;
  bottom: 6px;
  width: calc(100% - 60px);
}

.time-elapsed{
  height: 2px;
  border-radius: 4px;
  position: absolute;
  bottom: 6px;
  opacity: .8;
  background: linear-gradient( 68.4deg,  rgba(248,182,204,1) 0.5%, rgba(192,198,230,1) 49%, rgba(225,246,240,1) 99.8% );
  z-index: 6;
}

@keyframes color-change{
  0% {
    background-image: linear-gradient( 112.7deg,  rgba(162,247,241,1) 0.3%, rgba(199,146,255,1) 88.7% );
  }
  25% {
   background-image: linear-gradient( 179.9deg,  rgba(141,154,190,1) 1.9%, rgba(244,179,78,1) 39.6%, rgba(235,124,88,1) 61.5%, rgba(235,124,88,1) 75%, rgb(19, 5, 18) 93% );

  }
  50% {
    background: rgba(181,126,255,1);

  }
  75% {
    background:  rgba(104,199,255,1);
  }
  100% {
    background: rgb(255, 54, 40);
  }
}

.progress {
  /* display: flex;
  justify-content: flex-start;
  align-items: left; */
  width: calc(100% - 60px);
  position: absolute;
  bottom: 3px;
  z-index: 5;
  
}

#prgbar {
  background: transparent;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  height: 3px;
  border-radius: 4px;
}

.plsoptions {
  display: flex;
  justify-content: space-around;
  flex: 1;
}

.plsoptions span  {
  cursor: pointer;
}

.plsoptions span:hover {
  color: #2A6586;
}

.plsoptions span.active {
  color: rgb(32, 77, 104);
}

.time {
  position: absolute;
  right: 100px;
  /* opacity: 0;
  animation: fadeIn 2s;
  animation-fill-mode: forwards; */
}

.title {
  padding-top: 25px;
  padding-left: 27px;
  position: absolute;
  z-index: 3;
  pointer-events: none;
}

.header {
  font-size: 25px;
  font-style: italic;
}


.songContainer {
  cursor: pointer;
  display: inline-block;
  user-select: none;
  position: absolute;
  visibility: visible;
  transition: all .2s ease-out;
}


.songContainer:hover {
  opacity: 1 !important;
  color: pink;
}
.nowplaying-name{
  margin-right:7px;
}
.nowplaying-link1{
  animation:fadeIn 1s ease-out;
  animation-delay: 1s;

  opacity: 0;
  animation-fill-mode: forwards;
  cursor: pointer;

  width: 19px;
  margin-top: 2px;
  margin-left: 10px;
  /* margin-bottom: 30px;
  right: 55px;
  position: absolute; */
}
.nowplaying-link2{
  animation:fadeIn 1s ease-out;
  animation-delay: 1s;

  opacity: 0;
  animation-fill-mode: forwards;
  cursor: pointer;

  width: 19px;
  margin-top: 2px;
  margin-left: 10px;
  cursor: pointer;



  /* margin-bottom: 30px;
  right: 22px;
  position: absolute; */
}
/*desktop*/
@media only screen and (min-width: 768px) {
  .NowPlaying {
    height:20px
  }
}
/*mobile */

@media only screen and (max-width: 768px) /*mobile*/ {
  .nowplaying-link1{
    display: none
  }
  .nowplaying-link2{
    display: none
  }

  .nowplaying-name{
    margin-right:0px;
  }

  .time{
    display: none;
  }

  .volum{
    display: none;
  }
  .next{
    display: none;
  }
  .prev{
    display: none;
  }

  .title{
    padding-top: 29px;
    padding-left: 23px;
  }
}
